import { responsiveFontSizes } from "@material-ui/core";
import axios from "axios";
import { AnyAction } from "redux";
import { GetUserToken } from "../utils/functions";
import {
  EmailDto,
  IABN,
  IColumnPreferences,
  ICraneFormValues,
  IDefaultApprovalParameter,
  IDefaultApprovalParameterPost,
  IUserDetails,
  IUserDetailsFull,
  IUserOrganisation,
  IUserPermissions,
} from "./../utils/interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../___store";
import { useReadUserLazyQuery } from "../queries/generated/graphql";
import { formatFileName } from "../utils/functions";
import { FormStates } from "../utils/constantVariables";

// VARIABLE TO DEFINE APIs' URL
export const urlCranePermitApplication =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/CranePermitApplications";
export const urlAirport =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/Airports";
export const urlUser = process.env.REACT_APP_BACKEND_BASE_URL + "/api/Users";
export const urlAddUser =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/Users/AddUser/";
export const urlAddUserOrganisation =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/Users/AddUser/Organisation/";
export const urlUserDetails =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/UserDetails/";
export const urlComments =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/applications/comments";
export const urlUserPermissions =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/UserPermissions";
export const urlOrganisation =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/Organisation";
export const urlChangeFormState =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/craneApplication/state";
export const urlSurfaceSets =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/SurfaceSets";
export const urlAirportPaymentSettings =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/airports/paymentSettings";
export const urlCraneApplicationHistory =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/applications/history";
export const urlUserProfilePicture =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/UserProfilePicture";
export const urlEmail =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/application/email/sendEmail";
export const urlUserNotificationSettings =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api";
export const urlFileStorage =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/applications/files";
export const urlContactApplicant =
  process.env.REACT_APP_BACKEND_BASE_URL +
  "/api/ContactApplicant/EmailUserToUser";
export const urlCraneApplicationEmailHistory =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/EmailHistory";
export const urlColumnPreferences =
  process.env.REACT_APP_BACKEND_BASE_URL +
  "/api/applications/columnPreferences";
export const urlDefaultApprovalParamters =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/DefaultApprovalParameters";

// --------------------------------------- API SurfacesSets -----------------------------------
// Gets all surfaces uploaded in an airport
export async function GetAllSurfaceSetsApi(airportId: string, token: string) {
  let surfaces: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlSurfaceSets}/${airportId}`, config)
    .then((response) => {
      surfaces = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [surfaces, errors];
}

export async function GetLatestSurfaceSetsApi(
  airportId: string,
  token: string
) {
  let surfaces: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlSurfaceSets}/latest/${airportId}`, config)
    .then((response) => {
      surfaces = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [surfaces, errors];
}

// Delete a surfaceSet
export async function DeleteSurfaceSetsApi(
  surfaceSetId: string,
  token: string
) {
  let surfaces: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlSurfaceSets}/${surfaceSetId}`, config)
    .then((response) => {
      surfaces = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [surfaces, errors];
}

// ------------------------------- API User Permission ----------------------------------------
// Gets current user permission from the database using the API-GET: 'api/UserPermission/'
export async function GetUserPermissionsApi(email: string, token: string) {
  let permission: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlUserPermissions}/${email}`, config)
    .then((response) => {
      permission = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [permission, errors];
}
// Gets current user permission from the database using the API-GET: 'api/UserPermission/All'
export async function GetAllUsersPermissions(token: any) {
  let permission: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(urlUserPermissions, config)
    .then((response) => {
      permission = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [permission, errors];
}

// Update user permission from the database using the API-PUT: 'api/UserPermission'
export async function UpdateUsersPermissionsApi(
  userPermission: IUserPermissions,
  token: any
) {
  let permission: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(urlUserPermissions, userPermission, config)
    .then((response) => {
      permission = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [permission, errors];
}

export async function GetUserProfilePicture(userId: any, token: string) {
  let profilePicture: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlUserProfilePicture}/${userId}`, config)
    .then((response) => {
      profilePicture = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [profilePicture, errors];
}

export async function PostUserProfilePicture(
  userProfilePictureData: any,
  token: string
) {
  let errors: any = null;
  let values: any = [];
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlUserProfilePicture, userProfilePictureData, config)
    .then((response) => {
      values = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [values, errors];
}

// Update user permission from the database using the API-PUT: 'api/UserPermission'
export async function UpdateUserProfilePicture(
  userProfilePicture: any,
  userId: any,
  token: any
) {
  let resposne: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(`${urlUserProfilePicture}/${userId}`, userProfilePicture, config)
    .then((response) => {
      resposne = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [resposne, errors];
}

// ------------------------------- API User Details ----------------------------------------
// Gets user details from the database using the API-GET: 'api/UserDetails'
export async function GetUserDetails(token: any) {
  let user: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUserDetails, config)
    .then((response) => {
      user = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}
// --------------------------------API Crane PermitApplications----------------------------------
// Gets all crane application from the database using the API-GET: 'api/CranePermitApplications'
export async function GetAllApplications(token: any) {
  let allApplications: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlCranePermitApplication + "?PageNumber=1&PageSize=100", config)
    .then((response) => {
      allApplications = response.data;
    })
    .catch((error) => {
      console.error(`Error: ${error}`, (errors = error));
    });
  return [allApplications, errors];
}
// Gets a crane application from the database using the API-GET: 'api/CranePermitApplications/{id}'
export async function GetApplicationById(applicationId: number, token: any) {
  let allApplication: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlCranePermitApplication}/${applicationId}`, config)
    .then((response) => {
      allApplication = response.data;
    })
    .catch((error) => {
      console.error(`Error: ${error}`, (errors = error));
    });
  return [allApplication, errors];
}
// Add new crane application  using the API-POST:'api/CranePermitApplications'
export async function PostCraneApplication(
  craneData: ICraneFormValues,
  token: any
) {
  let errors: any = null;
  let values: any = [];
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlCranePermitApplication, craneData, config)
    .then((response) => {
      values = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [values, errors];
}
// Update a crane application using the API PUT: '/api​/CranePermitApplications​/{id}'
export async function UpdateCraneApplication(
  applicationId: number | undefined,
  craneData: ICraneFormValues,
  token: any
) {
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = urlCranePermitApplication + "/" + applicationId;
  await axios
    .put(url, craneData, config)
    .then((response) => {})
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });
  return errors;
}

export async function ChangeFormState(
  id: any,
  state: FormStates,
  token: any,
  paymentType?: string
) {
  let response: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlChangeFormState}/${id}/${state}/${paymentType}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });
  return [response, errors];
}

// Delete a crane application using the API DELETE: '/api​/CranePermitApplications​/{id}'
export async function DeleteCraneApplication(
  applicationId: number | undefined,
  token: any
) {
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = urlCranePermitApplication + "/" + applicationId;
  await axios
    .delete(url, config)
    .then((response) => {})
    .catch((error) => {
      errors = error;
    });
  return errors;
}

export async function GetHistoryByApplicationId(
  applicationId: any,
  token: any
) {
  let errors: any = null;
  let response: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlCraneApplicationHistory}/${applicationId}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [response, errors];
}

// Adds a commment for a crane Application
export async function PostHistory(HistoryData: any, token: any) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlCraneApplicationHistory, HistoryData, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

//Gets all comments from the database associated with crane application Id
export async function GetAllCommentsforCraneApplication(
  token: any,
  applicationId: any
) {
  let allComments: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlComments}/${applicationId}`, config)
    .then((response) => {
      allComments = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [allComments, errors];
}
// Gets all comments from the database
export async function GetAllComments(token: any) {
  let allComments: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlComments, config)
    .then((response) => {
      allComments = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [allComments, errors];
}
// Adds a commment for a crane Application
export async function AddCommentforCraneApplicaiton(
  commentData: any,
  token: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlComments, commentData, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}
// Updates a comment for a crane applicaiton
export async function UpdateCommentforCraneApplicaiton(
  commentID: any,
  commentData: any,
  token: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .put(urlComments + "/" + commentID, commentData, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}
// Deletes a comment for a crane application
export async function DeleteCommentforCraneApplicaiton(
  commentID: any,
  token: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlComments}/${commentID}`, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}
//-----------------------------------AIRPORTS API--------------------------------------------
// Gets all Airports from the database using the API-GET: 'api/Aisports'
export async function GetAllAirports(token: any) {
  let allAirports: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlAirport, config)
    .then((response) => {
      allAirports = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [allAirports, errors];
}

export async function GetAirportById(token: any, airportId: any) {
  let airport: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlAirport}/${airportId}`, config)
    .then((response) => {
      airport = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [airport, errors];
}

export async function GetAirportByOrgId(token: any, orgId: any) {
  let airports: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlAirport}/airportsByOrgId/${orgId}`, config)
    .then((response) => {
      airports = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [airports, errors];
}

// Adds a new airport in the database using the API-POST: 'api/Airports'
export async function AddAirport(airportData: any, token: any) {
  let result: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .post(urlAirport, airportData, config)
    .then((resp) => {
      result = resp.data;
    })
    .catch((error) => {
      errors = error;
    });

  return [result, errors];
}
// Updates an airport using the API-PUT: 'api/Airports/{airportId}'
export async function UpdateAirport(
  airportId: number,
  airportData: any,
  token: any
) {
  let response: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .put(urlAirport + "/" + airportId, airportData, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [response, errors];
}

// Delete an airport using the API-DELETE: 'api/Airports/{airportId}'
export async function DeleteAirportApi(airportId: string, token: any) {
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let result = await axios
    .delete(`${urlAirport}/${airportId}`, config)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return null;
    });

  return [result];
}

export async function UpdateApplicationClearances(
  airportId: number,
  token: any
) {
  let result: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .post(`${urlAirport}/${airportId}/refreshsurfaceresults`, {}, config)
    .then((resp) => {
      result = resp;
    })
    .catch((error) => {
      errors = error;
    });

  return [result, errors];
}

//------------------------------------PaymentSettings API-------------------------------------
//Get an airport payment settings by airport id
export async function GetPaymentSettingsByAirportId(
  airportId: any,
  token: any
) {
  let response: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let result = await axios
    .get(`${urlAirportPaymentSettings}/${airportId}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      errors = error;
    });
  return [response, errors];
}

export async function PostAirportPaymentSettings(
  paymentSettings: any,
  token: any
) {
  let AiportPaymentSettings: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlAirportPaymentSettings, paymentSettings, config)
    .then((resp) => {
      AiportPaymentSettings = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [AiportPaymentSettings, errors];
}

//update an aiports payment settings
export async function UpdateAirportPaymentSettings(
  paymentSettings: any,
  token: any
) {
  let paymentSettingsObject: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .put(`${urlAirportPaymentSettings}`, paymentSettings, config)
    .then((resp) => {
      paymentSettingsObject = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [paymentSettingsObject, errors];
}

// Delete aiports payment settings
export async function DeleteAirportPaymentSettings(
  airportId: string,
  token: any
) {
  let paymentSettings: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlAirportPaymentSettings}/${airportId}`, config)
    .then((resp) => {
      paymentSettings = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [paymentSettings, errors];
}

//-----------------------------------USERS API-------------------------------------------------
// Deletes a user from the database
export async function DeleteUserAPI(userId: any, token: any) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlUser}/${userId}`, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}
//

export async function GetUserApi(token: any) {
  let user: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUser, config)
    .then((response) => {
      user = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function GetAllUsersAPI(token: any) {
  let users: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUser, config)
    .then((response) => {
      users = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [users, errors];
}

export async function GetAllUsers(token: any) {
  let users: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlUser, config)
    .then((response) => {
      users = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [users, errors];
}

export async function GetCurrentUserApi(token: any) {
  let user: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlUser}/CurrentUser`, config)
    .then((resp) => {
      user = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function GetAllUsersByOrgId(token: any, orgId: any) {
  let users: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlUser}/GetAllUsersByOrgId/${orgId}`, config)
    .then((resp) => {
      users = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [users, errors];
}

export async function GetUserById(token: any, userID: any) {
  let user: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${urlUser}/${userID}`, config)
    .then((response) => {
      user = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [user, errors];
}

export async function AddUserApi(userDetails: any, token: any) {
  let newUser: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlAddUser, userDetails, config)
    .then((resp) => {
      newUser = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUser, errors];
}

export async function UpdateUserDetailsApi(userData: IUserDetails, token: any) {
  let errors: any = null;
  let userDetails: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = urlUserDetails;
  await axios
    .put(url, userData, config)
    .then((response) => {
      userDetails = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [userDetails, errors];
}

export async function AddUserDetailsApi(userDetails: IUserDetails, token: any) {
  let newUserDetails: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlUserDetails, userDetails, config)
    .then((resp) => {
      newUserDetails = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUserDetails, errors];
}

export async function AddUserOrganisationApi(
  organisationId: string,
  userDetails: IUserDetails,
  token: any
) {
  let newUserDetails: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlAddUserOrganisation + organisationId, userDetails, config)
    .then((resp) => {
      newUserDetails = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newUserDetails, errors];
}
//-----------------------------------Organisation API--------------------------------------------
// Add a organisation on the database
export async function AddOrganisationApi(organisation: any, token: any) {
  let newOrganisation: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlOrganisation, organisation, config)
    .then((resp) => {
      newOrganisation = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newOrganisation, errors];
}

// Update a organisation on the database
export async function UpdateOrganisationApi(organisation: any, token: any) {
  let newOrganisation: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .put(urlOrganisation, organisation, config)
    .then((resp) => {
      newOrganisation = resp.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [newOrganisation, errors];
}

// Gets all organisations from the database
export async function GetOrganisationsApi(token: any) {
  let organisations: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlOrganisation, config)
    .then((response) => {
      organisations = response.data;
    })
    .catch((error) => {
      errors = error;
    });

  return [organisations, errors];
}

// Gets organisation by ID from the database
export async function GetOrganisationApi(organisationId: string, token: any) {
  let organisations: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(urlOrganisation + "/" + organisationId, config)
    .then((response) => {
      organisations = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [organisations, errors];
}

// Delete an organisations from the database
export async function DeleteOrganisationsApi(
  organisationId: string,
  token: any
) {
  let organisation: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlOrganisation}/${organisationId}`, config)
    .then((response) => {
      organisation = response.data;
    })
    .catch((error) => {
      errors = error;
    });
  return [organisation, errors];
}

//-----------------------------------File Storage API--------------------------------------------
export async function GetAllFilesByAppID(token: any, appId: any) {
  let files: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/applications/files/${appId}`,
      config
    )
    .then((response) => {
      files = response.data;
    })
    .catch((error) => {
      console.error(`Error: ${error}`); // Debug code
      errors = error;
    });

  return [files, errors];
}

export async function GetTerrainVersionsByICAO(token: string, icao: string) {
  let files: any = [];
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/applications/files/terrainVersions/${icao}`,
      config
    )
    .then((response) => {
      files = response.data;
    })
    .catch((error) => {
      console.error(`Error: ${error}`); // Debug code
      errors = error;
    });

  return [files, errors];
}

export async function UploadFileToBlobStorage(
  token: any,
  fileData: any,
  appId: any,
  uploadTime: any
) {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  let result = await axios
    .post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/applications/files/upload/${appId}/${uploadTime}`,
      fileData,
      config
    )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(`Error: ${error}`); // Debug code
    });

  return result;
}
export async function DeleteFile(token: any, file: any, appId: any) {
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  let result = await axios
    .delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/applications/files/delete/${appId}/${file.filename}/${file.uploadedDateTime}/${file.id}`,
      config
    )
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return null;
    });

  return [result];
}

export async function DeleteEmailSignatureByOrgId(
  orgId: number,
  token: string
) {
  let response: any = null;
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlFileStorage}/deleteEmailSignature/${orgId}`, config)
    .then((response) => {
      response = response;
    })
    .catch((error) => {
      errors = error;
    });
  return [response, errors];
}

export async function DownloadFile(token: any, file: any, appId: any) {
  let errors: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  await axios({
    url: `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/applications/files/download/${appId}/${formatFileName(file)}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.filename);
    document.body.appendChild(link);
    link.click();
  });
}

export async function GetFileStorageDBStatusByFilename(
  token: any,
  filename: any,
  appId: any
) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      urlFileStorage + `/GetDBFileStorageStatusByFileName/${filename}/${appId}`,
      config
    )
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

export async function GetAllDBFilesbyAppId(token: any, appId: any) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(urlFileStorage + `/GetAllFilesByAppId/${appId}`, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

export async function PostFileStorageDB(token: any, FileStorageObject: any) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlFileStorage + "/PostFileStorageDB", FileStorageObject, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function UpdateFileStorageDB(
  token: any,
  FileStorageObject: any,
  filename: any,
  appId: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(
      urlFileStorage + `/FileStorageDB/${filename}/${appId}`,
      FileStorageObject,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function DeleteFileStorageDBSetting(id: any, token: any) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(`${urlFileStorage}/DeleteFileStorageDBbyFilename/${id}`, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

//----------------------------------------- ABN API --------------------------------------------
// Gets Organisation information from ABN Lookup (https://abr.business.gov.au/Tools/WebServices)
export async function GetAbnApi(ABN: any) {
  let key = "c94cd141-0608-48ab-ad78-7aa823832be1";
  let info: any = [];
  let errors: any = null;

  await axios
    .get(
      `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${ABN}&guid=${key}`
    )
    .then((response) => {
      info = response.data;
    })
    .catch((error) => {
      errors = error;
    });

  return [info, errors];
}

//----------------------------------------- eWay API --------------------------------------------
export async function GeteWayAccessCode(
  token: any,
  transactionId: any,
  paymentAmount: any
) {
  let paymentObject: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/application/Payments/${transactionId}/details/${paymentAmount}`,
      config
    )
    .then((response) => {
      paymentObject = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [paymentObject, errors];
}

export async function PostTransaction(token: any, transactionData: any) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(
      process.env.REACT_APP_BACKEND_BASE_URL + "/transactions",
      transactionData,
      config
    )
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function UpdateTransaction(
  token: any,
  transactionData: any,
  transactionID: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/transactions/${transactionID}`,
      transactionData,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function GetTransactionById(token: any, transactionID: any) {
  let transaction: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/transactions/${transactionID}`,
      config
    )
    .then((response) => {
      transaction = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [transaction, errors];
}

export async function GeteWayPaymentDetails(token: any, accessCode: any) {
  let paymentDetails: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/application/Payments/${accessCode}`,
      config
    )
    .then((response) => {
      paymentDetails = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [paymentDetails, errors];
}

//----------------------------------------- surfaces API --------------------------------------------
export async function GetSubSetIds(token: any, rootId: any) {
  let subsetIds: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/Surfaces/${rootId}/Children`,
      config
    )
    .then((response) => {
      subsetIds = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [subsetIds, errors];
}

export async function GetSurfacesByAirportId(token: any, airportId: any) {
  let surfaces: any = [];
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/Airports/${airportId}/surfaces`,
      config
    )
    .then((response) => {
      surfaces = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [surfaces, errors];
}

export async function GetSurfaceFeaturesById(token: string, surfaceId: number) {
  let surfaces: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/Surfaces/${surfaceId}/Feature`,
      config
    )
    .then((response) => {
      surfaces = response;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [surfaces, errors];
}

//-------------------- Email API's -----------------------

export async function SendEmail(token: any, emailObject: EmailDto) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlEmail, emailObject, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function SendEmailUserToUser(
  token: any,
  emailObject: EmailDto,
  appId: number
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(urlContactApplicant + `/${appId}`, emailObject, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function GetEmailHistoryByAppId(token: any, appId: number) {
  let response: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlCraneApplicationEmailHistory}/${appId}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [response, errors];
}

//----------------------------------------- UserNotificationSettings Api --------------------------------------------

export async function GetUserNotificatioinSettingsByAppId(
  token: any,
  appId: any
) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(urlUserNotificationSettings + `/notificationSettings/${appId}`, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

export async function GetEnabledUserNotificationSettingsByUserId(
  token: any,
  userId: any
) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      urlUserNotificationSettings +
        `/enabledUserNotificationSettings/${userId}`,
      config
    )
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

export async function GetCurrentNotificationSettingByAppIdAndUserId(
  token: any,
  userId: any,
  appId: any
) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(
      urlUserNotificationSettings +
        `/currentAppNotificationSettingsByAppId&UserId/${appId}/${userId}`,
      config
    )
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

export async function PostUserNotificationSettings(
  token: any,
  userNotificationSettingsObject: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(
      urlUserNotificationSettings + "/userNotificationSettings",
      userNotificationSettingsObject,
      config
    )
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function UpdateUserNotificationSettings(
  token: any,
  userNotificatonSettingsObject: any,
  notificationId: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(
      urlUserNotificationSettings +
        `/userNotificationSettings/${notificationId}`,
      userNotificatonSettingsObject,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function DeleteUserNotificationSettings(
  token: any,
  appId: any,
  userId: any
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(
      `${urlUserNotificationSettings}/userNotificationSettings/${userId}/${appId}`,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function MigrateDBHistoryLogs(token: any) {
  let errors: any = null;
  let responses: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(urlCranePermitApplication + `/MigrateDBHistoryLogs`, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));

  return [responses, errors];
}

//----------------------------------------- Column Preferences Api --------------------------------------------
export async function GetColumnPreferencesByUserId(token: any, userId: number) {
  let response: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlColumnPreferences}/${userId}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [response, errors];
}

export async function PostColumnPreferences(
  token: any,
  columnPreferences: IColumnPreferences
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(`${urlColumnPreferences}`, columnPreferences, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function UpdateColumnPreferences(
  token: any,
  columnPreferences: IColumnPreferences
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(
      `${urlColumnPreferences}/${columnPreferences.userId}`,
      columnPreferences,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

//----------------------------------------- Default Approval Parameters Api --------------------------------------------
export async function GetDefaultApprovalParametersByAirportId(
  token: any,
  airportId: number
) {
  let response: any = null;
  let errors: any = null;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .get(`${urlDefaultApprovalParamters}/${airportId}`, config)
    .then((resp) => {
      response = resp;
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      errors = error;
    });

  return [response, errors];
}

export async function UpdateDefaultApprovalParameter(
  token: any,
  defaultApprovalParameter: IDefaultApprovalParameter
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .put(`${urlDefaultApprovalParamters}`, defaultApprovalParameter, config)
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function PostDefaultApprovalParameter(
  token: any,
  defaultApprovalParameter: IDefaultApprovalParameterPost[]
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .post(`${urlDefaultApprovalParamters}`, defaultApprovalParameter, config)
    .then((resp) => {
      responses = resp;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}

export async function DeleteDefaultApprovalParameter(
  token: string,
  defaultApprovalParameterId: number
) {
  let errors: any = null;
  let responses: any = null;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .delete(
      `${urlDefaultApprovalParamters}/${defaultApprovalParameterId}`,
      config
    )
    .then((response) => {
      responses = response;
    })
    .catch((error) => (errors = error));
  return [responses, errors];
}
